<div class="main-container">
  <div class="logo"></div>
  <div class="content">
    <div class="title">MANTENIMIENTO EN PROGRESO</div>
    <div class="description">
      Estamos trabajando para brindarles el mejor servicio, el sitio volverá a
      funcionar pronto.
    </div>
  </div>
</div>
