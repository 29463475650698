<div class="container-fluid pt-4 spacer-1">
  <div class="row justify-content-center mt-4">
    <div class="col-12 text-center">
		<img src="assets/imgs/logo.svg" width="150px" alt="">
    </div>
  </div>

  <div class="row justify-content-center mt-4">
	  <div class="col-12 text-center">
		  <span class="text">PROXIMAMENTE</span>
		  <p class="text">disponible para dispositivos iOS y Android.</p>
	  </div>
  </div> 

  <div class="row justify-content-center mt-4">
    <div class="col-2"></div>
    <div class="col-8">
		<img class="sobrados" src="assets/imgs/logo-playstore.png" width="100%" alt="">
    </div>
    <div class="col-2"></div>
  </div>

  <div class="row justify-content-center mt-2">
    <div class="col-2"></div>
    <div class="col-8">
		<img class="sobrado" src="assets/imgs/logo-appstore.png" width="100%"  alt="">
    </div>
    <div class="col-2"></div>
  </div>
</div>  
