<div class="container-fluid p-0 spacer-1">
  <div class="row justify-content-center pt-4 pr-4 pb-4">
    <div class="col mt-4 ml-4">
      <p class="privacy-title" dir="ltr">
        <span>POLÍTICA DE PRIVACIDAD</span>
      </p>
      <p class="privacy-normal-content" dir="ltr">
        <span>Este portal web es de propiedad de&nbsp;</span
        ><span class="special-content"> MERCADOGAMER S.R.L.</span
        ><span
          >&nbsp;- CUIT 30717242307, quienes respetan la privacidad de cada
          individuo que visita nuestro Sitio Web. De esta forma, le informamos
          que:</span
        >
      </p>
      <p class="privacy-normal-content" dir="ltr">
        <span
          >La presente Política de Privacidad (en adelante la “Política de
          Privacidad”) se integra con los Términos y Condiciones disponibles en
          la página web y se aplicarán a los Usuarios del Sitio Web&nbsp;</span
        ><a
          href="https://mercadogamer.com"
          class="content-link"
          target="_blank"
        >
          MERCADOGAMER.COM</a
        ><span
          >&nbsp;estén o no debidamente registrados. Las definiciones utilizadas
          en los Términos y Condiciones subsistirán y serán aplicable en las
          presente Políticas de Privacidad.</span
        >
      </p>
      <p class="privacy-normal-content" dir="ltr">
        <span
          >MERCADOGAMER.COM podrá modificar la Política de Privacidad en
          cualquier momento. Las nuevas versiones de la Política de Privacidad
          serán notificadas previamente a su entrada en vigencia mediante: (i)
          publicación de dicha nueva versión en nuestra plataforma de software
          online y/o (ii) por mensaje de correo electrónico dirigido al
          Usuario.</span
        >
      </p>
      <p class="privacy-normal-content" dir="ltr">
        <span
          >El Usuario reconoce y acepta que MERCADOGAMER.COM, puede recolectar
          información acerca de los Usuarios al momento de registrarse y navegar
          en la plataforma, tales como: su nombre, apellido, datos de contacto,
          correo electrónico, intereses, y demás datos que figuren en los
          formularios de registro de alta, como así también de aquellas personas
          que escriban por cualquiera de los puntos de contacto dentro del
          Portal Web.</span
        >
      </p>
      <p class="privacy-normal-content" dir="ltr">
        <span
          >Los Datos serán almacenados en una base de datos, en servidores
          externos contratados por MERCADOGAMER.COM, que serán tratados en forma
          confidencial y con las debidas medidas de seguridad que se detallarán
          más adelante.</span
        >
      </p>
      <p class="privacy-normal-content" dir="ltr">
        <span
          >El Usuario acepta que será dado por notificado de cualquier
          modificación a la Política de Privacidad una vez que MERCADOGAMER.COM
          hubiera publicado las mismas en el Portal Web, y que la continuación
          del Usuario en el uso de los Servicios, una vez publicada dicha nueva
          versión, se considerará como aceptación de dichas modificaciones a la
          Política de Privacidad.
        </span>
      </p>
      <p class="privacy-normal-content" dir="ltr">
        <span
          >La utilización de esta información podrá ser utilizados para
          administrar y organizar las campañas comerciales de MERCADOGAMER.COM y
          las agencias/concesionarios que participen dentro del Portal, de
          marketing y/o de publicidad (por ejemplo, para desarrollar o promover
          productos o servicios complementarios).
        </span>
      </p>
      <p class="privacy-normal-content" dir="ltr">
        <span
          >En consecuencia, el Usuario acepta: (i) chequear las Políticas de
          Privacidad periódicamente; y (ii) leer cualquier mensaje de correo
          electrónico que sea enviado por MERCADOGAMER.COM, con cualquier
          modificación o nueva versión.
        </span>
      </p>
      <p class="privacy-normal-content" dir="ltr">
        <span
          >MERCADOGAMER.COM, reconoce el principio que establece la ley 25.326,
          que sin importar donde estén almacenados o como se utilicen los datos,
          estos siempre son propiedad de su titular. Es así, que se tomarán las
          precauciones pertinentes para evitar la pérdida, mal uso o alteración
          de la información que usted nos proporcione, considerándola como
          confidencial y no se usará la información para nada más que para los
          fines de los servicios objeto del evento celebrado al que participan
          los usuarios.
        </span>
      </p>
      <p class="privacy-normal-content" dir="ltr">
        <span
          >MERCADOGAMER.COM no almacenará ni recopilará la información y datos
          de los medios de pago de los Usuarios como ser los números de las
          tarjetas de crédito o cuentas bancarias, etc. Dicha información será
          almacenada y/o recopilada por pasarelas de pago externas provistos por
          otras compañías, como ser Prisma, Pay U, Mercado Pago, Payoneer, entre
          otros, no teniendo MERCADOGAMER.COM acceso a la misma o a dichos
          servidores en donde se encuentran almacenados. En tal sentido,
          MERCADOGAMER.COM no se hace responsable de ningún daño o perjuicio que
          pueda ocurrir al momento de realizar pagos o transacciones a través de
          dichos proveedores.
        </span>
      </p>
      <p class="privacy-normal-content" dir="ltr">
        <span
          >Los Datos de los usuarios de nuestro Sitio Web serán tratados en los
          términos previstos por la Ley Nacional de Protección de Datos
          Personales N° 25.326. Usted, como titular de los datos personales,
          tiene la facultad de ejercer el derecho de acceso a los mismos en
          forma gratuita a intervalos no inferiores a seis meses, salvo que se
          acredite un interés legítimo al efecto conforme lo establecido en el
          artículo 14, inciso 3 de la Ley N° 25.326.
        </span>
      </p>
      <p class="privacy-normal-content" dir="ltr">
        <span
          >Si nos ha proporcionado información sobre Usted y desea copias de
          esta información, o si desea que corrijamos cualquier inexactitud de
          tal información, o si desea que eliminemos esa información de nuestros
          registros, por favor deberá escribir al correo informado en el Portal
          Web soporte@mercadogamer.com o escribirnos a: Maipú 971, piso 4to,
          Oficina “D”, Ciudad Autónoma de Buenos Aires – Argentina y haremos lo
          que esté de nuestra parte para proporcionar, corregir o eliminar la
          información sobre usted en nuestros archivos. Ante cualquier
          inconveniente, se le informa que la Dirección Nacional de Protección
          de Datos Personales, órgano de control de la Ley 2326, tiene la
          atribución de atender las denuncias y reclamos con relación al
          incumplimiento de las normas sobre protección de datos personales.
        </span>
      </p>
      <p class="privacy-normal-content" dir="ltr">
        <span
          >Como se le informó, MERCADOGAMER.COM recaudará los datos
          suministrados por los usuarios para sí utilizando los Datos
          exclusivamente con fines comerciales, de marketing y/o publicitarios.
          De esta forma, garantizamos la confidencialidad, uso y acceso
          exclusivo a los Datos y le recordamos que la aceptación de los
          Términos nos autoriza a utilizar sus Datos obtenidos de acuerdo a las
          finalidades y de la manera aquí expresada.
        </span>
      </p>

      <p dir="ltr" class="privacy-title">
        <span>FINALIDAD DE LOS DATOS</span>
      </p>
      <p dir="ltr" class="privacy-normal-content">
        <span
          >Los Datos suministrados por el Usuario y recolectados de conformidad
          con lo establecido en las presentes Políticas de Privacidad serán
          utilizados con la siguiente finalidad:</span
        >
      </p>
      <ul class="rules-list">
        <li>Identificación del Usuario;</li>
        <li>Facturación y cobro del servicio contratado;</li>
        <li>Posibilidad de dar acceso al Servicio;</li>
        <li>
          Poder contar con un teléfono o dirección de correo electrónico para
          contactarse con el Usuario por motivos de venta o información de
          productos indicados, como de interés, por parte del Usuario;
        </li>
        <li>
          Poder brindarle la información solicitada sobre productos o servicios,
          promociones y oportunidades de compra de todas las
          empresas/anunciantes participes del Portal Web.
        </li>
        <li>
          Registro de los intereses de los usuarios a los fines de enviar
          información promocional de productos o servicios de las
          Empresas/anunciantes del Portal Web. En dicho caso, si el Usuario lo
          deseara, podrá solicitar que se lo elimine de las listas para el envío
          de información promocional o publicitaria;
        </li>
      </ul>

      <p dir="ltr" class="privacy-normal-content">
        <span
          >MERCADOGAMER.COM no recolectará datos con otra finalidad distinta o
          incompatible con la finalidad expuesta en esta política de privacidad
          ni recolectará datos por medios desleales, fraudulentos o en forma
          contraria a las disposiciones legales vigentes.
        </span>
      </p>
      <p dir="ltr" class="privacy-normal-content">
        <span
          >Los datos que se recaben del usuario deben ser ciertos, adecuados,
          pertinentes, exactos y no excesivos en relación al ámbito y a la
          finalidad que motivaron su recolección. Serán almacenados facilitando,
          en todo momento, el ejercicio de los derechos de sus titulares y
          actualizados en el caso de ser necesario, como así también serán
          destruidos cuando hayan dejado de ser necesarios o pertinentes a los
          fines para los cuales fueron recolectados mediante una adecuada
          política de eliminación de datos personales. Ninguna persona puede ser
          obligada a proporcionar datos sensibles, por lo que MERCADOGAMER.COM
          no recolecta ni trata dicha categoría de datos, dando cumplimiento a
          la legislación vigente.
        </span>
      </p>
      <p dir="ltr" class="privacy-normal-content">
        <span
          >MERCADOGAMER.COM, podrá compartir la información con otras empresas
          de servicios o sitios de internet dedicados a la evaluación de las
          conductas de los Usuarios de internet o similares a los fines de
          mejorar la calidad de los servicios ofrecidos. Generalmente dichas
          empresas o sitios de internet poseen sus propias políticas de
          privacidad de datos a los fines de su protección. De todas maneras,
          MERCADOGAMER.COM, empeñará sus mejores esfuerzos en que la privacidad
          de la información compartida sea protegida de la mejor manera posible.
          Sin perjuicio de ello, MERCADOGAMER.COM no será responsable por los
          daños provocados por tales empresas y/o sitios de internet en cuanto a
          su deber de protección, confidencialidad y privacidad de los datos que
          ellas manejan.
        </span>
      </p>
      <p dir="ltr" class="privacy-normal-content">
        <span
          >Cada uno de estos servicios de terceros cuentan con sus propias
          políticas de privacidad y proveen, en los casos que se encuentre
          disponible, un método con el que Usuario podrá hacer un opt-out (optar
          por no o rechazar); entre ellos por ejemplo:
        </span>
      </p>

      <ul class="rules-link">
        <li>
          <a
            href="http://www.google.com/privacy.html"
            style="text-decoration: none"
            target="_blank"
            >http://www.google.com/privacy.html</a
          >
        </li>
        <li>
          <a
            href="http://info.yahoo.com/privacy/us/biz/rightmedia/details.html"
            style="text-decoration: none"
            target="_blank"
            >http://info.yahoo.com/privacy/us/biz/rightmedia/details.html</a
          >
        </li>
        <li>
          <a
            href="http://www.assistly.com/privacy"
            style="text-decoration: none"
            target="_blank"
            >http://www.assistly.com/privacy</a
          >
        </li>
        <li>
          <a
            href="http://highrisehq.com/privacy"
            style="text-decoration: none"
            target="_blank"
            >http://highrisehq.com/privacy</a
          >
        </li>
        <li>
          <a
            href="http://mailchimp.com/legal/privacy/"
            style="text-decoration: none"
            target="_blank"
            >http://mailchimp.com/legal/privacy/</a
          >
        </li>
        <li>
          <a
            href="https://www.prismamediosdepago.com/legales"
            style="text-decoration: none"
            target="_blank"
            >https://www.prismamediosdepago.com/legales</a
          >
        </li>
        <li>
          <a
            href=" https://vimeo.com/terms"
            style="text-decoration: none"
            target="_blank"
            >https://vimeo.com/terms</a
          >
        </li>
        <li>
          <a
            href="https://www.mercadopago.com.ar/ayuda/gateway_3145"
            style="text-decoration: none"
            target="_blank"
            >https://www.mercadopago.com.ar/ayuda/gateway_3145</a
          >
        </li>
      </ul>

      <p dir="ltr" class="privacy-normal-content">
        <span
          >MERCADOGAMER.COM utilizará los Datos provistos por el Usuario, y los
          recolectados, conforme a lo establecido en las Políticas de Privacidad
          y no los divulgará salvo que sea solicitada por tribunales, u
          organismos estatales nacionales o internacionales que así lo requieran
          y lo soliciten en la forma correspondiente. MERCADOGAMER.COM no estará
          obligado a retener la información durante ningún plazo establecido y
          dispondrá la eliminación de la misma cuando lo juzgue conveniente.
        </span>
      </p>
      <p dir="ltr" class="privacy-normal-content">
        <span
          >El Portal Web podrá contener enlaces a otros sitios de internet que
          no sean propiedad de MERCADOGAMER.COM, en consecuencia,
          MERCADOGAMER.COM no será responsable por el actuar de dichos sitios de
          internet, a los cuales no se aplicará la presente Política de
          Privacidad. Recomendamos examinar la política de privacidad detallada
          en aquellos sitios de internet para entender los procedimientos de
          recolección de información que utilizan y como protegen sus datos
          personales.
        </span>
      </p>

      <p dir="ltr" class="privacy-title">
        <span>CESION DE LOS DATOS</span>
      </p>
      <p dir="ltr" class="privacy-normal-content">
        <span
          >MERCADOGAMER.COM no venderá, alquilará ni compartirá los Datos de los
          Usuarios salvo aquellos casos expresamente previstos en las Políticas
          de Privacidad. No obstante, el Usuario presta su expresa conformidad
          para que MERCADOGAMER.COM transfiera total o parcialmente los datos
          del Usuario a cualquiera de sus sociedades controladas, controlantes
          y/o vinculadas con MERCADOGAMER.COM.
        </span>
      </p>

      <p dir="ltr" class="privacy-title">
        <span>COOKIES</span>
      </p>
      <p dir="ltr" class="privacy-normal-content">
        <span
          >El Usuario reconoce y acepta que el Sitio podrá utilizar cookies. En
          esta web se utilizan cookies
          <span class="special-content">propias y de terceros</span> para
          conseguir que tengas una mejor experiencia de navegación, puedas
          compartir contenido en redes sociales, para mostrarte anuncios en
          función a tus intereses, para obtener estadísticas de usuarios, y para
          brindar a los Usuarios un servicio más completo, recordando sus
          preferencias
        </span>
      </p>
      <p dir="ltr" class="privacy-normal-content">
        <span
          >La información que recopile MERCADOGAMER.COM podrá incluir el
          comportamiento de navegación, dirección IP, logs, y otros tipos de
          información. Sin embargo, MERCADOGAMER.COM no recolectará información
          personal identificable de manera directa de ningún Usuario usando
          cookies o tags.
        </span>
      </p>
      <p dir="ltr" class="privacy-normal-content">
        <span
          >Un cookie es un pequeño archivo de texto mediante el cual se puede
          identificar en forma exclusiva un navegador de internet, y que suele
          ser instalado, enviado o escrito en el ordenador de los Usuarios de
          internet, cuando los mismos ingresan a determinados sitios de
          internet, a efectos de obtener automáticamente información acerca de
          la conducta de los Usuarios cuando navegan por dichos sitios de
          internet. Un archivo cookie generalmente contendrá el nombre del
          dominio desde donde la cookie ha sido transferida, la fecha de
          creación, y un código de identificación. La única información personal
          que un cookie puede contener es información que el Usuario mismo
          suministra con su navegación por internet, tales como la dirección de
          IP, las páginas visitadas y en qué orden, el tiempo de permanencia en
          cada página, la fecha y la hora, etc. (la “Información de Actividad”).
          Un cookie no puede leer datos del disco duro del Usuario ni leer los
          archivos cookie creados por otros sitios de internet.
        </span>
      </p>
      <p dir="ltr" class="privacy-normal-content">
        <span
          >La mayoría de los navegadores están configurados para aceptar
          cookies, pero los Usuarios podrán reconfigurar su navegador de
          internet para rechazar todas las cookies o para que el sistema le
          indique en qué momento se envía una. Sin embargo, si las cookies están
          inhabilitadas, es posible que algunas características y servicios de
          los sitios de internet no funcionen de manera adecuada.
        </span>
      </p>
      <p dir="ltr" class="privacy-normal-content">
        <span
          >Los Usuarios podrán optar por que su navegador de internet no sea
          identificado por los Servicios. En tal caso, el Usuario deberá acudir
          a la sección de ayuda de su navegador.
        </span>
      </p>
      <p dir="ltr" class="privacy-normal-content">
        <span
          >MERCADOGAMER.COM puede modificar ocasionalmente la configuración de
          cookies y que aparezcan cookies no detalladas en la presente política.
          Es importante que sepas que son cookies provisionales que no siempre
          es posible informar y que solo tienen finalidades de estudio y
          valoración. En ningún caso se van a utilizar cookies que comprometan
          tu privacidad.
        </span>
      </p>
      <p dir="ltr" class="privacy-normal-content">
        <span
          >MERCADOGAMER.COM hace uso de diferentes herramientas con la finalidad
          de ayudar a identificar y rastrear a los visitantes del sitio web y
          redes sociales; utilizando Google Analytics, remarketing o los
          informes de impresiones de la Red de Display de Google, entre otros.
          Los visitantes pueden inhabilitar Google Analytics para la publicidad
          de display y personalizar los anuncios de la Red de Display de Google
          mediante la Configuración de anuncios. El usuario también puede hacer
          uso del complemento de inhabilitación para navegadores de Google
          HYPERLINK
          <a href="https://tools.google.com/dlpage/gaoptout"
            >"https://tools.google.com/dlpage/gaoptout/" </a
          >Analytics.
        </span>
      </p>
      <p dir="ltr" class="privacy-normal-content">
        <span
          >MERCADOGAMER.COM utiliza la función de remarketing con Google
          Analytics para publicar anuncios online. Otros proveedores, incluido
          Google, muestran sus anuncios en sitios de Internet y otros
          proveedores, incluido Google, usan las cookies de origen (como la
          cookie de Google Analytics) y las cookies de terceros (como la cookie
          de DoubleClick) combinadas para informar, optimizar y publicar
          anuncios basándose en las visitas anteriores al sitio web.
        </span>
      </p>
      <p dir="ltr" class="privacy-normal-content">
        <span
          >MERCADOGAMER.COM y otros proveedores, incluido Google, usan las
          cookies de origen (como la cookie de Google Analytics) y las cookies
          de terceros (como la cookie de DoubleClick) combinadas para informar
          sobre cómo se relacionan con las visitas al sitio las impresiones de
          los anuncios, otros usos de los servicios publicitarios y las
          interacciones con estas impresiones de los anuncios y servicios
          publicitarios.
        </span>
      </p>
    </div>
  </div>
</div>
