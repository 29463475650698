<div class="container-fluid d-flex justify-content-center">
  <div class="mt-6 mb-6">
    <div class="d-flex flex-column">
      <div class="card">
        <div class="card-header">
          <div class="container-img mt-3">
            <span class="title">Recuperar contraseña</span>
          </div>
        </div>
        <div class="card-body p-0 mt-3">
          <form class="w-100 pt-3 container" [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="form-group mb-4">
              <input type="text" class="form-control" formControlName="username" placeholder="Email">
            </div>
            <button class="btn btn-primary btn-block mb-4" type="submit" [disabled]="!form.valid">
              Enviar
            </button>
            <div class="row mb-4">
              <div class="col-6 text-center" >
                <a [routerLink]="['/login']" class="remember">
                  <span>Ir al Login</span>
                </a>
              </div>
              <div class="col-6 text-center" >
                <a [routerLink]="['/register']" class="remember">
                  <span>Registrate!</span>
                </a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

