<div class="container">
  <div class="logo-container">
    <div class="title">Bienvenidos a</div>
    <div
      class="image-container"
      style="background-image: url('assets/imgs/logo-landing-2.png')"
    ></div>
  </div>
  <div class="content">
    <mat-select
      [(value)]="selectedCountry"
      class="country-select"
      placeholder="Seleccionar País"
    >
      <mat-select-trigger>
        <div
          class="icon"
          [style]="global.getBackgroundUrl(selectedCountry?.picture)"
        ></div>
        <div class="name">{{ selectedCountry?.name }}</div>
      </mat-select-trigger>
      <mat-option
        *ngFor="let country of countries"
        class="country-select-item"
        [value]="country"
      >
        <div
          class="icon"
          [style]="global.getBackgroundUrl(country?.picture)"
        ></div>
        <div class="name">{{ country?.name }}</div>
      </mat-option>
    </mat-select>

    <button
      class="btn custom-btn mt-3"
      [ngClass]="selectedCountry !== 'select' ? 'enabled-btn' : ''"
      (click)="goToHome()"
      [disabled]="selectedCountry === 'select'"
    >
      Entrar
    </button>
  </div>
</div>
