<div class="p-0 spacer-1">
  <div class="row justify-content-center pt-4 pr-4 pb-4">
    <div class="col mt-4 ml-4">
      <p class="terms-title" dir="ltr">
        <span>MERCADOGAMER – TERMINOS Y CONDICIONES</span>
      </p>

      <p class="terms-content" dir="ltr">
        <span>¡Gracias por ingresar a nuestro sitio web</span
        ><a href="http://www.mercadogamer.com/" style="text-decoration: none"
          ><span> www.mercadogamer.com </span></a
        ><span
          >(en adelante el "Tienda Virtual"). Por favor lea los “Términos y
          Condiciones” (en adelante los "Términos") antes de usar la tienda
          virtual.</span
        >
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >MERCADOGAMER es una tienda online que permite a vendedores externos
          ofrecer y vender sus artículos o productos digitales en línea,
          conectando a compradores y vendedores dentro de su portal web.</span
        >
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >La tienda virtual es operada por <span>MERCADOGAMER S.R.L.</span> -
          CUIT 30717242307. Al utilizarlo, Usted manifiesta estar de acuerdo y
          aceptar los Términos bajo los cuales MERCADOGAMER le da acceso. De vez
          en cuando MERCADOGAMER podría modificar los Términos en consideración
          de la normativa aplicable y/o por decisión de la compañía por lo cual
          siempre estarán disponibles para tu revisión. En consecuencia, le
          recomendamos que por favor continúe revisando los Términos cada vez
          que utilice la tienda virtual.  Si en cualquier momento usted decide
          no aceptar los Términos, puede no utilizar la tienda virtual.
        </span>
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >Cuando MERCADOGAMER lo crea conveniente podrá llevar a cabo concursos
          y promociones a través de la tienda virtual. En donde corresponda,
          serán presentados “Términos y condiciones” por separado para tales
          concursos y promociones los cuales le serán debidamente notificados y
          Usted, en caso de participar, deberá aceptar. </span
        >
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >Es requisito necesario para la adquisición de los productos que se
          ofrecen en este sitio, que lea y acepte los siguientes Términos y
          Condiciones que a continuación se redactan. El uso de nuestros
          servicios, así como la compra de los productos, implicará que usted ha
          leído y aceptado los Términos y Condiciones de Uso en el presente
          documento. Todos los productos que son ofrecidos por nuestro sitio web
          pudieran ser creadas, cobradas, enviadas o presentadas por una página
          web tercera y en tal caso estarían sujetas a sus propios Términos y
          Condiciones. En algunos casos, para adquirir un producto, será
          necesario el registro por parte del usuario, con ingreso de datos
          personales fidedignos y definición de una contraseña. </span
        >
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >El usuario puede elegir y cambiar la clave para su acceso de
          administración de la cuenta en cualquier momento, en caso de que se
          haya registrado y que sea necesario para la compra de alguno de los
          productos. MERCADOGAMER no asume la responsabilidad en caso de que
          entregue dicha clave a terceros. </span
        >
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >Todas las compras y transacciones que se lleven a cabo por medio de
          este sitio web, están sujetas a un proceso de confirmación y
          verificación, por nosotros o terceros, el cual podría incluir la
          verificación del stock y disponibilidad de producto, validación de la
          forma de pago, validación de la factura (en caso de existir) y el
          cumplimiento de las condiciones requeridas por el medio de pago
          seleccionado. En algunos casos puede que se requiera una verificación
          por medio de correo electrónico.</span
        >
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >Los precios de los productos ofrecidos en esta Tienda Online son
          válidos solamente en las compras realizadas en este sitio web.</span
        >
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >MERCADOGAMER, se compromete a no utilizar su nombre y/o datos
          personales sin su autorización ya sea para acciones comerciales y/o
          promociones o concursos. </span
        >
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >MERCADOGAMER declina toda responsabilidad por las dificultades
          encontradas en el acceso al sitio web o por las fallas en las líneas
          de comunicación, por serle ajenas a este. </span
        >
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >MERCADOGAMER está feliz de darles la bienvenida a su tienda virtual
          para presentar sus actividades a Usted y a la mayor audiencia
          posible. </span
        >
      </p>

      <p class="terms-title" dir="ltr">
        <span>TÉRMINOS Y CONDICIONES</span>
      </p>

      <p class="terms-title" dir="ltr">
        <span>ROL DE MERCADOGAMAR</span>
      </p>

      <p class="terms-content" dir="ltr">
        <span
          >A pesar de que MERCADOGAMER facilita las transacciones a través
          de</span
        ><a href="http://www.mercadogamer.com/" style="text-decoration: none"
          ><span> www.mercadogamer.com </span></a
        ><span
          >-tienda virtual-, MERCADOGAMER no es ni el comprador ni el vendedor
          de los productos ofrecidos por vendedores externos. MERCADOGAMER
          simplemente facilita un espacio donde compradores y vendedores pueden
          negociar y efectuar sus transacciones. Por consiguiente, el
          correspondiente contrato que se genera una vez se ha realizado la
          compra de cualquier producto vendido por un vendedor externo,
          concierne única y exclusivamente al comprador y al vendedor de dicho
          producto. MERCADOGAMER no forma parte de ese contrato, no asume
          ninguna responsabilidad relacionada con el mismo, ni actúa como
          representante del vendedor. El vendedor es responsable de la venta de
          sus productos, así como de ofrecer asistencia en cuanto a las
          reclamaciones del comprador, o con respecto a cualquier asunto
          relacionado con dicho contrato entre el comprador y vendedor.
        </span>
      </p>

      <p>
        <span
          style="background-color: rgb(20, 20, 20); color: rgb(255, 255, 255)"
          ><br
        /></span>
      </p>
      <p>
        <span
          style="background-color: rgb(20, 20, 20); color: rgb(255, 255, 255)"
          ><br
        /></span>
      </p>

      <p class="terms-title" dir="ltr">
        <span>CONDICIONES DE ACCESO Y UTILIZACIÓN DE LA PLATAFORMA ONLINE</span>
      </p>

      <ul class="terms-list">
        <li dir="ltr">&nbsp;Condición de Usuario</li>
      </ul>
      <p class="terms-content" dir="ltr">
        <span
          >La aceptación de los términos y condiciones y política de privacidad
          conjuntamente con la utilización de cualquier Servicio del Portal
          atribuye la Condición de Usuario del mismo.
        </span>
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >Los visitantes solo podrán ver secciones limitadas del Sitio Web,
          siendo requisito ser un usuario Registrado para poder continuar con la
          navegación y compra de artículos o productos dentro de la tienda
          online.
        </span>
      </p>

      <ul class="terms-list">
        <li dir="ltr">&nbsp;Idioma</li>
      </ul>

      <p class="terms-content" dir="ltr">
        <span
          >Todo el contenido proporcionado en la página web de MERCADOGAMER es
          en idioma castellano, tanto la estructura del Sitio como sus servicios
          y contenido.
        </span>
      </p>

      <ul class="terms-list">
        <li dir="ltr">&nbsp;Edad para contratar el servicio.</li>
      </ul>

      <p class="terms-content" dir="ltr">
        <span
          >Los Servicios están destinados y solo pueden ser utilizados por: (a)
          personas mayores de 18 años que puedan celebrar contratos legalmente
          vinculantes según la ley aplicable, y (b) personas que tengan menos de
          18 años de edad, pero que utilicen el Servicios a través de una cuenta
          propiedad de un padre o tutor legal con el permiso correspondiente y
          bajo su supervisión directa.
        </span>
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >Además de la cláusula (b) anterior, si usted es quien tiene la
          responsabilidad parental o tutor legal, es responsable de todas y cada
          una de las actividades realizadas en su cuenta. Como ejemplos, y sin
          limitar la oración anterior, el padre/madre o tutor legal debe aprobar
          todas las transacciones en relación con MERCADOGAMER, y toda la
          información de pago debe pertenecer al padre/madre o tutor legal
          responsable de la cuenta.
        </span>
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >Al utilizar los Servicios, usted declara y garantiza que cumple con
          los criterios anteriores y que toda la información de registro que
          envía es precisa y veraz. MERCADOGAMER puede verificar la edad del
          propietario de la cuenta. Como parte del proceso de verificación,
          MERCADOGAMER puede requerir que el propietario de la cuenta
          proporcione información de identificación adicional, que incluye,
          entre otros, una copia de su DNI – Documento Único de Identidad (u
          otra forma de identificación emitida por el gobierno).
        </span>
      </p>
      <ul class="terms-list">
        <li dir="ltr">&nbsp;Necesidad de registro</li>
      </ul>

      <p class="terms-content" dir="ltr">
        <span
          >Con carácter general, para el acceso a los servicios de la Tienda
          Online, será necesario el Registro del Usuario del Portal. No
          obstante, las utilizaciones de determinados Servicios podrían estar
          accesibles para todo el público, sin necesidad de registración. Este
          registro se efectuará en la forma expresamente señalada en el propio
          Servicio o en las Condiciones Particulares que le sean de aplicación.
        </span>
      </p>

      <ul class="terms-list">
        <li dir="ltr">
          &nbsp;Condiciones para Usuarios Vendedor y Usuarios Cliente
        </li>
      </ul>
      <p class="terms-content" dir="ltr">
        <span
          >Nuestra tienda online se halla orientado a dos tipos de usuarios:
          “USUARIOS VENDEDOR" y "USUARIOS CLIENTE".
        </span>
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >"USUARIOS CLIENTE” refiere a aquellas personas que se han registrado
          y que utilizan MERCADOGAMER para adquirir un articulo o producto
          digital a través de los medios y facilidades brindados por la
          plataforma y/o terceros.
        </span>
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >“USUARIOS VENDEDOR" son aquellas empresas y/o personas que se han
          registrado y que utilizan MERCADOGAMER para ofrecer sus servicios,
          articulos y/o productos digitales a los USUARIOS CLIENTES.
        </span>
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >Un mismo USUARIO CLIENTE podrá actuar como USUARIO VENDEDOR,
          cumpliendo con las correspondientes registraciones.
        </span>
      </p>

      <ul class="terms-list">
        <li dir="ltr">&nbsp;Servicios de Pago</li>
      </ul>
      <p class="terms-content" dir="ltr">
        <span
          >Con carácter general los Servicios ofertados a través del Portal
          tendrán contraprestación económica, en la forma y términos que se
          determine en las Condiciones Particulares del Servicio.
        </span>
      </p>
      <ul class="terms-list">
        <li dir="ltr">&nbsp;Precios - Formas y Condiciones de Pago</li>
      </ul>
      <p class="terms-content" dir="ltr">
        <span
          >El usuario vendedor deberá incluir en el anuncio información sobre la
          disponibilidad de los productos que se venden, detallándolo la
          información posible de cada uno de los productos, y el precio del
          producto –el cual puede variar según la moneda que se visualice
          dependiendo el país de residencia-.
        </span>
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >Todos los precios incluyen los impuestos legalmente aplicables, los
          cuales pueden variar dependiendo el país de residencia y el medio de
          pago utilizado.
        </span>
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >Al realizar alguna transacción comercial dentro de la plataforma
          online, el comprador acepta recibir factura electrónica por dicha
          operación.
        </span>
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >El Sitio Web ofrece diferentes pasarelas de pagos (Gateways) –las
          cuales tendrán sus propios términos y condiciones y políticas de
          privacidad–. Se deja constancia que las plataformas de pago pertenecen
          a terceros, siendo totalmente ajenas y sin ningún tipo de control por
          parte de MERCADOGAMER. Por lo que MERCADOGAMER no responde por su
          funcionamiento, interface, manejo de datos, seguridad, ni de ningún
          otro tipo; quedando los usuarios sujetos a términos y condiciones
          propios de esos terceros, por lo que será su exclusiva responsabilidad
          informarse sobre los mismos.
        </span>
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >Todas las operaciones están sujetas a un costo adicional de
          transacción, el cual se detalla al momento de finalizar la orden de
          pago, el cual corresponde al costo de procesamiento de la plataforma.
        </span>
      </p>

      <ul class="terms-list">
        <li dir="ltr">&nbsp;Baja del servicio</li>
      </ul>
      <p class="terms-content" dir="ltr">
        <span
          >El CLIENTE y/o VENDEDOR –Anunciante- tiene derecho a desistir del
          Servicio sin necesidad de justificación y sin aplicación de ninguna
          multa, procediendo con la baja del usuario creado, previo aviso a
          MERCADOGAMER.
        </span>
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >Asimismo, como consecuencia de la baja y/o suspensión del usuario, se
          darán de baja todas las publicaciones efectuadas, que al momento se
          encuentren activas en el sitio web.
        </span>
      </p>

      <ul class="terms-list">
        <li dir="ltr">&nbsp;Uso del Portal y sus servicios</li>
      </ul>
      <p class="terms-content" dir="ltr">
        <span
          >El Usuario reconoce y acepta que el uso de los contenidos y/o
          servicios ofrecidos por MERCADOGAMER será bajo su exclusivo riesgo y/o
          responsabilidad.
        </span>
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >El Usuario se compromete a utilizar el Portal y todo su contenido y
          Servicios conforme a lo establecido en la ley, la moral, el orden
          público y en las presentes Condiciones Generales de Uso, y en las
          Condiciones Particulares que, en su caso, le sean de aplicación.
          Asimismo, se compromete a hacer un uso adecuado de los servicios y/o
          contenidos del Portal y a no emplearlos para realizar actividades
          ilícitas o constitutivas de delito, que atenten contra los derechos de
          terceros y/o que infrinjan la regulación sobre propiedad intelectual e
          industrial, o cualesquiera otras normas del ordenamiento jurídico
          aplicable.
        </span>
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >MERCADOGAMER no responde por las limitaciones geográficas que puedan
          suscitar a la hora de querer acceder a su cuenta o reproducir el
          contenido fuera del ámbito geográfico, que figure como residencia en
          su formulario de alta, ya que las licencias de contenido pueden ir
          variando según la ubicación geografía.
        </span>
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >El Usuario se compromete a no trasmitir, introducir, difundir y poner
          a disposición de terceros, cualquier tipo de material e información
          (datos contenidos, mensajes, dibujos, archivos de sonido e imagen,
          fotografías, software, etc.) que sean contrarios a la ley, la moral,
          el orden público y las presentes Condiciones Generales de Uso y, en su
          caso, a las Condiciones Particulares que le sean de aplicación. A
          título enunciativo, y en ningún caso limitativo o excluyente, el
          Usuario se compromete a:
        </span>
      </p>
      <ul class="terms-sm-list">
        <li dir="ltr">
          &nbsp;No introducir o difundir contenidos o propaganda de carácter
          racista, xenófobo, pornográfico, de apología del terrorismo o que
          atenten contra los derechos humanos, como así tampoco utilizar
          lenguaje inadecuado y/o inmoral.
        </li>
        <li dir="ltr">
          &nbsp;No introducir o difundir en la red programas de datos (virus y
          software nocivo) susceptibles de provocar daños en los sistemas
          informáticos del proveedor de acceso, sus proveedores o terceros
          usuarios de la red Internet.
        </li>
        <li dir="ltr">
          &nbsp;No difundir, transmitir o poner a disposición de terceros
          cualquier tipo de información, elemento o contenido que atente contra
          los derechos fundamentales y las libertades públicas, reconocidos
          constitucionalmente y en los tratados internacionales.
        </li>
        <li dir="ltr">
          &nbsp;No difundir, transmitir o poner a disposición de terceros
          cualquier tipo de información, elemento o contenido que constituya
          publicidad ilícita o desleal.
        </li>
        <li dir="ltr">
          &nbsp;No transmitir publicidad no solicitada o autorizada, material
          publicitario, "correo basura", "cartas en cadena", "estructuras
          piramidales", o cualquier otra forma de solicitación, excepto en
          aquellas áreas (tales como espacios comerciales) que hayan sido
          exclusivamente concebidas para ello.
        </li>
        <li dir="ltr">
          &nbsp;No introducir o difundir cualquier información y contenidos
          falsos, ambiguos o inexactos de forma que induzca a error a los
          receptores de la información.
        </li>
        <li dir="ltr">
          &nbsp;No suplantar a otros usuarios utilizando sus claves de registro
          a los distintos servicios y/o contenidos de los Portales.
        </li>
        <li dir="ltr">
          &nbsp;No difundir, transmitir o poner a disposición de terceros
          cualquier tipo de información, elemento o contenido que suponga una
          violación de los derechos de propiedad intelectual e industrial,
          patentes, marcas o copyright que correspondan a los titulares de los
          Portales o a terceros.
        </li>
        <li dir="ltr">
          &nbsp;No difundir, transmitir o poner a disposición de terceros
          cualquier tipo de información, elemento o contenido que suponga una
          violación del secreto de las comunicaciones y la legislación de datos
          de carácter personal.
        </li>
      </ul>

      <ul class="terms-list">
        <li dir="ltr">&nbsp;Protección de datos e información</li>
      </ul>
      <p class="terms-content" dir="ltr">
        <span
          >MERCADOGAMER se basa en la confianza y la privacidad. Toda la
          información personal proporcionada a MERCADOGAMER y a sus anunciantes
          será tratada con la máxima seguridad, cuidado y confidencialidad.
          MERCADOGAMER procesará la información acerca de usted, de acuerdo con
          los términos de nuestra Política de Privacidad.
        </span>
      </p>
      <ul class="terms-list">
        <li dir="ltr">&nbsp;Comunicaciones electrónicas</li>
      </ul>
      <p class="terms-content" dir="ltr">
        <span
          >Cada vez que utilices un Servicio de MERCADOGAMER o envíes un correo
          electrónico, o cualquier otra comunicación desde tu ordenador o
          dispositivo móvil, estarás comunicándote electrónicamente con
          nosotros. Nosotros nos pondremos en contacto contigo electrónicamente
          por distintos medios, como por ejemplo mediante correo electrónico, o
          publicando mensajes o comunicaciones por correo electrónico en el
          sitio web. A efectos del presente contrato, aceptas que todos los
          contratos, avisos y otras notificaciones y comunicaciones que te
          enviemos por medios electrónicos satisfacen cualquier requisito de
          forma escrita, salvo que cualquier legislación aplicable con carácter
          imperativo exigiera una forma distinta de comunicación.
        </span>
      </p>
      <ul class="terms-list">
        <li dir="ltr">&nbsp;Disputas con usuarios o terceros</li>
      </ul>
      <p class="terms-content" dir="ltr">
        <span
          >En caso de que surja una disputa entre los usuarios o un tercero, le
          sugerimos que se ponga en contacto con el usuario o con el tercero
          para resolver la disputa de manera amistosa.
        </span>
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >Si un comprador y un vendedor en MERCADOGAMER no pueden resolver una
          disputa resultante de una transacción que ocurre en MERCADOGAMER,
          deberá abrir un reclamo –dentro de los 3 (tres) días de realizada la
          compra– desde “Mi cuenta” / “Compras” y activar la Garantía
          MercadoGamer.
        </span>
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >La Garantía MercadoGamer define que cada compra hecha dentro de la
          plataforma MERCADOGAMER tiene respaldo en caso de: no entrega de
          producto, este vencido, no funcione correctamente o no sea el producto
          descripto en la publicación.
        </span>
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >MERCADOGAMER lo hace a su entera discreción y de buena fe, sin ningún
          tipo de obligación, ya que MERCADOGAMER no tiene la obligación de
          resolver disputas entre usuarios o entre usuarios y vendedores.
        </span>
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >MERCADOGAMER lo hace a su entera discreción y de buena fe, sin ningún
          tipo de obligación, ya que MERCADOGAMER no tiene la obligación de
          resolver disputas entre usuarios o entre usuarios y vendedores.
        </span>
      </p>
      <p class="terms-title" dir="ltr">
        <span>DERECHOS DE AUTOR y PROPIEDAD INTELECTUAL</span>
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >De acuerdo con la ley 11.723 y concordantes que rigen los derechos de
          propiedad intelectual como son los de naturaleza y extensión literaria
          y artística u otros derechos similares, la publicación, edición,
          reproducción, redistribución o falsificación de los elementos que
          componen la tienda virtual de MERCADOGAMER, en su totalidad o en
          parte, está estrictamente prohibida de conformidad con dicha ley en
          tanto todas las marcas mencionadas en este documento como así también
          en la tienda virtual son marcas registradas. 
        </span>
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >La tienda virtual, incluyendo (pero no limitado a) texto, contenido,
          software, video, música, sonido, gráficos, fotografías, ilustraciones,
          arte, nombres, logos, marcas registradas, marcas de servicios y demás
          material (en adelante "el Contenido") está protegido por derechos de
          autor, marcas registradas, patentes y/u otros derechos de propiedad.
        </span>
      </p>
      <p class="terms-title" dir="ltr">
        <span>CONFIABILIDAD</span>
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >Si bien MERCADOGAMER se ha empeñado en asegurar la exactitud de la
          información a la que se tiene acceso por medio de este Sitio Web, no
          se garantiza, ni proporciona ninguna garantía en cuanto a la
          exactitud, oportunidad o integridad de cualquier información o
          material en este Sitio Web, la cual puede haber sido modificada y/o
          eliminada sin previo aviso. MERCADOGAMER podrá, cuando se presenten
          situaciones no imputables a esta y no previstas en los presentes
          Términos, modificar cualquiera de los puntos enunciados en estos
          Términos, dando en su caso la debida publicación y llevando a cabo, en
          caso de corresponder, los procedimientos legales necesarios ante la
          autoridad de aplicación.
        </span>
      </p>
      <p class="terms-title" dir="ltr">
        <span>NO GARANTÍA</span>
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >La tienda virtual y el Contenido son proporcionados "tal cual"
          excluyendo garantías de cualquier tipo, ya sean expresas o implícitas,
          hasta el límite total permisible según las leyes correspondientes
          incluyendo (pero no limitadas a) la exclusión de garantías de título,
          comercialización, calidad satisfactoria, adecuación a un propósito en
          particular, y la no infracción de derechos de propiedad o de terceros.
        </span>
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >MERCADOGAMER no acepta responsabilidad alguna por la funcionalidad
          contenida en la tienda virtual, y no ofrece garantía de que la tienda
          virtual operará ininterrumpidamente o libre de errores o que los
          defectos serán corregidos.
        </span>
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >MERCADOGAMER no asume responsabilidad de ninguna índole, si en razón
          del acceso al Sitio Web o a los sitios web vinculados, por cualquier
          transferencia de datos, el equipo del usuario se viese afectado por
          algún virus, wornms, “caballo de troya”, o por la presencia de otros
          elementos en los contenidos que puedan producir alteraciones en el
          sistema informático, documentos electrónicos o ficheros de los
          usuarios o por cualquier daño que pueda sufrir en su equipo como
          resultado de tales agentes destructivos.
        </span>
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >En ningún caso, MERCADOGAMER será responsable, ni deberá responder
          ante ninguna acción directa, indirecta o incidental originada por, o
          vinculada con, el uso de la tienda virtual, con los contenidos y el
          software del Sitio y/o la información y/o los servicios y/o los
          productos ofrecidos a través del Sitio y/o derivado del uso del mismo,
          aún en el supuesto caso en que la legislación aplicable al mismo no
          autorice la exclusión y/o limitación de responsabilidad.
        </span>
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >MERCADOGAMER tampoco es responsable de la confiabilidad o
          disponibilidad continua de las líneas telefónicas y el equipo que
          usted utiliza para acceder al sitio web. Estos Términos no afectan sus
          derechos reglamentarios o sus derechos legales como consumidor. 
        </span>
      </p>
      <p class="terms-title" dir="ltr">
        <span>LIMITANTES DE RESPONSABILIDADES</span>
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >Usted reconoce que la utilización del Sito Web, incluyendo el
          Contenido, es bajo su propio riesgo. Sí no está satisfecho con la
          tienda virtual, los Términos o cualquier parte del Contenido, el único
          remedio es descartar la utilización de la tienda virtual. Bajo ninguna
          circunstancia MERCADOGAMER tendrá responsabilidad para con usted o
          cualquier tercero por cualquier daño directo, especial, indirecto,
          consiguiente o incidental, pérdida de ganancias, o cualquier daño de
          cualquier tipo ya sea que esté basado en garantía, contrato, agravio
          (incluyendo negligencia) o cualquier otro, aún si MERCADOGAMER ha sido
          advertido de dicha posibilidad. Las leyes correspondientes pueden no
          permitir la limitación o exclusión de responsabilidades por daños
          incidentales o consiguientes, por lo que esta limitación o exclusión
          puede no ser válida para usted. 
        </span>
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >MERCADOGAMER no será responsable de (i) cualesquiera pérdidas que no
          fueran atribuibles a incumplimiento alguno por su parte, (ii) pérdidas
          empresariales (incluyendo lucro cesante, de ingresos, de contratos, de
          ahorros previstos, de datos, pérdida del fondo de comercio o gastos
          innecesarios incurridos), o de (iii) cualesquiera pérdidas indirectas
          o de carácter consecuencial que no fueran razonablemente previsibles
          por ambas partes en el momento en que se formalizó el contrato de
          compraventa de los productos entre ambas partes.
        </span>
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >Si adquieres el producto en tu condición de "consumidor", tienes
          derecho a la garantía de conformidad prevista en la legislación según
          la cual el bien entregado ha de ser conforme al contrato, y a la que
          legalmente viene obligado el vendedor (Garantía Legal).
        </span>
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >De forma adicional, podrías tener derecho a cualquier otra garantía
          (la Garantía Comercial) que en tu caso pudiera otorgar el fabricante o
          un tercero. Generalmente el nombre de la entidad que otorga la
          Garantía Comercial, así como los términos y condiciones de la misma
          figura en la documentación incluida en el embalaje del producto, que
          te invitamos a leer y conservar en tu poder.
        </span>
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >MERCADOGAMER no será responsable del cumplimiento de las obligaciones
          previstas en la Garantía Comercial ofrecida por terceros.
        </span>
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >En consecuencia, nuestra responsabilidad se limitará al máximo
          alcance que la ley aplicable permita, y bajo ninguna circunstancia
          seremos responsables ante Usted por la pérdida de ganancias, ingresos
          o información, ni por daños consecuentes, especiales, indirectos,
          ejemplares, punitivos o incidentales que surjan a raíz de la
          utilización de la tienda virtual. 
        </span>
      </p>
      <p class="terms-title" dir="ltr">
        <span>TERMINACIÓN</span>
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >Ya sea MERCADOGAMER o usted pueden dar por terminadas estos Términos
          con o sin causa en cualquier momento. Si MERCADOGAMER rescinde estos
          Términos, le enviará, en caso de corresponder, un e-mail a la
          dirección proporcionada en el registro si es que lo hubiese habido, y
          usted será considerando para recibirlo dentro de una hora desde su
          transmisión. La terminación será efectiva en ese momento. Usted será
          responsable, en caso de corresponder, de notificarnos a
          <a style="color: #f78a0e">soporte@mercadogamer.com</a> cualquier
          cambio en su dirección de correo electrónico.
        </span>
      </p>
      <p class="terms-title" dir="ltr">
        <span>CAMBIOS AL SITIO WEB</span>
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >Usted acepta que MERCADOGAMER tiene el derecho de cambiar el
          contenido o las especificaciones técnicas de cualquier aspecto de la
          tienda virtual en cualquier momento y a discreción de MERCADOGAMER
          solamente. Usted además acepta que tales cambios podrían resultar en
          la imposibilidad suya de acceder a la tienda virtual. 
        </span>
      </p>
      <p class="terms-title" dir="ltr">
        <span>VÍNCULOS AL SITIO WEB / TIENDA VIRTUAL</span>
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >Los Sitios Web o páginas a los cuales está vinculado este Sitio Web
          son sólo para efectos de información, y no han sido revisados por
          MERCADOGAMER quien no se hace responsable por el contenido de los
          Sitios Web vinculados o que vinculan a este Sitio Web en tanto no son
          de su propiedad y operados por este. MERCADOGAMER no se hace
          responsable por cualquier pérdida, infracción o similar que resulte de
          la utilización de cualquier parte de los sitios web vinculados o que
          vinculan a este Sitio Web. MERCADOGAMER no deberá ser responsabilizado
          del contenido proporcionado por terceras partes en virtud de que no
          tiene control sobre tales sitios. Asimismo, MERCADOGAMER no se
          responsabiliza por la interpretación y/o mal interpretación de los
          contenidos del Sitio y/o de los sitios web vinculados en forma
          explícita o implícita, ni del uso indebido de los mismos, ni de
          perjuicios reales, directos o indirectos, invocados por quienes
          aleguen haber sido inducidos a tomar decisiones al consultar el Sitio,
          los sitios vinculados y/o sus servicios.  Es importante aclararte que
          cuando decides usar aplicaciones, sitios web u otros servicios de
          terceros que utilizan nuestro Sitio Web o están integrados y/o
          vinculados con ellos, estas plataformas pueden recibir información
          acerca del contenido que compartes y/o visualizas. 
        </span>
      </p>
      <p class="terms-title" dir="ltr">
        <span>CONTENIDOS Y SERVICIOS DE TERCEROS</span>
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >MERCADOGAMER no controla previamente, aprueba ni hace propios los
          contenidos, servicios, opiniones, comunicaciones datos, archivos,
          productos y cualquier clase de información de terceros, personas
          jurídicas o físicas, recogidos en el Portal. De igual forma, no
          garantiza la licitud, fiabilidad, utilidad, veracidad, exactitud,
          exhaustividad y actualidad de los contenidos, informaciones y
          Servicios de terceros en el Portal.
        </span>
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >MERCADOGAMER no controla con carácter previo y no garantiza la
          ausencia de virus y otros elementos en los Contenidos y servicios
          prestados por terceros a través del Portal que puedan introducir
          alteraciones en el sistema informático, documentos electrónicos o
          ficheros de los usuarios.
        </span>
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >MERCADOGAMER no será responsable, ni indirectamente ni
          subsidiariamente, de los daños y perjuicios de cualquier naturaleza
          derivados de la utilización y contratación de los Contenidos y de los
          Servicios de terceros en el Portal, así como de la falta de licitud,
          fiabilidad, utilidad, veracidad, exactitud, exhaustividad y actualidad
          de los mismos. Con carácter enunciativo, y en ningún caso limitativo,
          no será responsable por los daños y perjuicios de cualquier naturaleza
          derivados de a) la infracción de los derechos propiedad intelectual e
          industrial y el cumplimiento defectuoso o incumplimiento de los
          compromisos contractuales adquiridos por terceros; b) la realización
          de actos de competencia desleal y publicidad ilícita; c) la
          inadecuación y defraudación de las expectativas de los Servicios,
          Productos y/o Contenidos de los terceros; d) los vicios y defectos de
          toda clase de los Productos, Servicios y/o contenidos de terceros
          prestados a través de
          <a href="http://www.mercadogamer.com">www.mercadogamer.com</a>.
        </span>
      </p>
      <p class="terms-title" dir="ltr">
        <span>CONDUCTA DE LOS USUARIOS</span>
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >MERCADOGAMER no garantiza que los Usuarios del Portal utilicen los
          contenidos y/o servicios del mismo de conformidad con la ley, la
          moral, el orden público, ni las presentes Condiciones Generales y, en
          su caso, las condiciones Particulares que resulten de aplicación.
          Asimismo, no garantiza la veracidad y exactitud, exhaustividad y/o
          autenticidad de los datos proporcionados por los Usuarios.
        </span>
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >MERCADOGAMER no será responsable, indirecta ni subsidiariamente, de
          los daños y perjuicios de cualquier naturaleza derivados de la
          utilización de los Servicios y Contenidos del Portal por parte de los
          Usuarios o que puedan derivarse de la falta de veracidad, exactitud
          y/o autenticidad de los datos o informaciones proporcionadas por los
          Usuarios, o de la suplantación de la identidad de un tercero efectuada
          por un Usuario en cualquier clase de actuación a través del Portal. A
          título enunciativo, pero no limitativo, MERCADOGAMER no será
          responsable indirecta o subsidiariamente de a) los contenidos,
          informaciones, opiniones y manifestaciones de cualquier Usuario o de
          terceras personas o entidades que se comuniquen o exhiban a través del
          Portal; b) los daños y perjuicios causados a terceros derivados de la
          utilización por parte del Usuario de los servicios y contenidos del
          Portal; c) Los daños y perjuicios causados por la falta de veracidad,
          exactitud o incorrección de la identidad de los usuarios y de toda
          información que éstos proporcionen o hagan accesible a otros usuarios;
          d) de los daños y perjuicios derivados de infracciones de cualquier
          usuario que afecten a los derechos de otro usuario, o de terceros,
          incluyendo los derechos de copyright, marca, patentes, información
          confidencial y cualquier otro derecho de propiedad intelectual e
          industrial.
        </span>
      </p>
      <p class="terms-title" dir="ltr">
        <span>CONTRATACIÓN CON TERCEROS A TRAVÉS DEL PORTAL</span>
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >El Usuario reconoce y acepta que cualquier relación contractual o
          extracontractual que, en su caso, formalice con los anunciantes o
          terceras personas contactadas a través del Portal, así como su
          participación en concursos, promociones, compraventa de bienes o
          servicios, se entienden realizados única y exclusivamente entre el
          Usuario y el anunciante y/o tercera persona. En consecuencia, el
          Usuario acepta que MERCADOGAMER, no tiene ningún tipo de
          responsabilidad sobre los daños o perjuicios de cualquier naturaleza
          ocasionados con motivo de sus negociaciones, conversaciones y/o
          relaciones contractuales o extracontractuales con los anunciantes o
          terceras personas físicas o jurídicas contactadas a través del Portal.
        </span>
      </p>
      <p class="terms-title" dir="ltr">
        <span>PUBLICIDAD DE ANUNCIOS EN OTROS PORTALES O REDES SOCIALES</span>
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >La publicación de un anuncio en
          <a href="www.mercadogamaer.com">www.mercadogamaer.com</a>, autoriza a
          MERCADOGAMER a hacer uso del contenido para ser enviado y publicado en
          el resto de portales y/o redes sociales de MERCADOGAMER habilitados a
          tal efecto. Dicho envío y publicación implica que el anuncio, así como
          las fotografías y los datos facilitados, se repliquen en los portales
          de destino en función de sus características y requisitos técnicos.
          MERCADOGAMER podrá en todo momento editar el anuncio y suspender o
          pausar su publicación.
        </span>
      </p>
      <p class="terms-title" dir="ltr">
        <span>NORMAS DE PUBLICACIÓN DE LOS ANUNCIOS</span>
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >Con carácter general, la publicación de Anuncios en el Portal estará
          sujeta a las siguientes reglas de redacción:
        </span>
      </p>
      <ul class="terms-list">
        <li dir="ltr">&nbsp;Ser usuario registrado y autorizado.</li>
        <li dir="ltr">
          &nbsp;Tener un producto que califique como articulo o producto
          digital, que pueda ser comercializado y cuente con los permisos y
          licencias correspondientes para aquel.
        </li>
        <li dir="ltr">
          &nbsp;Completar todos los apartados del formulario de alta del
          Anuncio.
        </li>
        <li dir="ltr">
          &nbsp;No superar el límite máximo del campo de texto del anuncio.
        </li>
        <li dir="ltr">
          &nbsp;Para la inserción de Anuncios deberá completar correctamente los
          campos obligatorios que así se indican.
        </li>
        <li dir="ltr">
          &nbsp;Y respetar las condiciones expresadas en los puntos anteriores
          de los Términos y Condiciones.
        </li>
      </ul>
      <p class="terms-title" dir="ltr">
        <span
          >LIMITACIÓN DE LA RESPONSABILIDAD EN PUBLICACIÓN DE LOS ANUNCIOS DE
          VENTA Y/O PUBLICITARIOS</span
        >
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >El Anunciante reconoce y acepta que la responsabilidad máxima de
          MERCADOGAMER frente al Anunciante que pudiera derivarse de la
          inserción de Anuncios en el Portal y en cualquiera de sus web sites es
          la siguiente: Si MERCADOGAMER no insertara el Anuncio en las fechas
          previstas, si el Anuncio no fuera insertado en la forma y términos
          acordados, ya sea por razones técnicas o de cualquier otro tipo, o por
          cualquier otra causa, la responsabilidad máxima queda limitada a la
          reinserción del Anuncio con posterioridad en una posición semejante.
        </span>
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >En ningún caso MERCADOGAMER responderá por otro tipo de daños, ya
          sean efectivos, indirectos, o de cualquier otro tipo, ni por el lucro
          cesante que hubiera podido sufrir el Anunciante por la falta de
          publicación puntual y/o correcta de cualquier Anuncio.
        </span>
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >MERCADOGAMER no responderá por los retrasos, publicación errónea ni
          por la falta de la publicación del anuncio que sean consecuencia de
          hechos o circunstancias que estén fuera de su control, incluido, a
          título enunciativo y no limitativo, acción gubernativa, incendio,
          inundación, insurrección, terremoto, fallo técnico, motín, explosión,
          embargo, huelga legal o ilegal, escasez de personal o de material,
          interrupción del transporte de cualquier tipo, retraso en el trabajo,
          o cualquier otra circunstancia fuera del control de MERCADOGAMER.
        </span>
      </p>
      <p class="terms-title" dir="ltr">
        <span>DERECHO A RECHAZAR EL ANUNCIO POR MERCADOGAMER</span>
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >MERCADOGAMER no se obliga a revisar previamente el contenido de
          ningún Anuncio, y cualquier revisión o aprobación efectuada por
          MERCADOGAMER no debe entenderse como aceptación de que dicho Anuncio
          cumple los términos de las CONDICIONES GENERALES DE CONTRATACION.
        </span>
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >MERCADOGAMER se reserva el derecho, ejercitable en cualquier momento
          y de modo discrecional, a:
        </span>
      </p>
      <ul class="terms-list">
        <li dir="ltr">
          &nbsp;Rechazar cualquier Anuncio, la orden de inserción de anuncios, o
          compromiso de ubicación.
        </li>
        <li dir="ltr">
          &nbsp;Eliminar cualquier anuncio del Directorio MERCADOGAMER o de
          cualquier página de la que sea titular.
        </li>
        <li dir="ltr">
          &nbsp;Suspender cualquier vendedor, anuncio y/o producto por no
          cumplir con los términos y condiciones anteriormente planteados.
        </li>
      </ul>
      <p class="terms-title" dir="ltr">
        <span>INDEPENDENCIA DE LAS CLAUSULAS</span>
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >Si alguna de las presentes condiciones fuera declarada inválida, nula
          o por cualquier causa ineficaz, dicha condición se entenderá excluida
          sin que dicha declaración pueda afectar a la validez ni a la
          exigibilidad del resto de condiciones.
        </span>
      </p>
      <p class="terms-title" dir="ltr">
        <span>LEYES VIGENTES Y JURISDICCIÓN</span>
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >Los Términos y Condiciones están regidos por las leyes de la
          República Argentina y las partes se sujetan a la jurisdicción
          exclusiva de los Tribunales Ordinarios en lo Comercial de la República
          Argentina renunciando así a cualquier otro fuero o jurisdicción que
          pudiera corresponderle. Cualquier acción relacionada con los Términos
          debe ser presentada ante los tribunales ordinarios de la Ciudad
          Autónoma de Buenos Aires y Usted acepta de manera irrevocable la
          jurisdicción pactada.
        </span>
      </p>
      <p class="terms-title" dir="ltr">
        <span>CONDICIONES PARTICULARES: </span>
      </p>
      <p class="terms-title" dir="ltr">
        <span>CONTRATACIÓN DEL SERVICIO DE ANUNCIOS - VENDEDORES </span>
      </p>
      <p class="terms-title" dir="ltr">
        <span>DESCRIPCIÓN DEL SERVICIO</span>
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >El Servicio consiste en la inserción de anuncios en la tienda online
          <a href="www.mercadogamer.com">www.mercadogamer.com</a>, con el objeto
          de publicar sus artículos y/o productos en el portal web y algunos
          casos redes sociales (de corresponder). El anunciante conoce y acepta
          que el anuncio publicado en
          <a href="www.mercadogamer.com">www.mercadogamer.com</a> debe cumplir
          con las condiciones solicitadas por MERCADOGAMER y el pago de una
          comisión por venta concretada.
        </span>
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >MERCADOGAMER ofrece una tienda virtual para la publicidad y venta o
          reventa de productos digitales
        </span>
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >MERCADOGAMER se reserva el derecho a difundir los Anuncios del
          Anunciante en terceros portales, así como en cualquier otro tipo de
          formato, además de otros sitios web (tales como redes sociales,
          plataformas de streamings o blogs), de forma total o parcial,
          aceptando el Anunciante dicha condición.
        </span>
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >Asimismo, al subir fotografías al Portal, el usuario cede
          gratuitamente a MERCADOGAMER los derechos de explotación de propiedad
          intelectual sobre las mismas, por lo que MERCADOGAMER podrá
          reproducirlas, transformarlas (incluyendo, sin limitación, la
          inclusión de marcas de agua u otros mecanismos que impidan el
          aprovechamiento no consentido por parte de terceros), distribuirlas y
          comunicarlas al público (incluida la puesta a disposición del público)
          a través de cualquier modalidad de explotación y utilizando cualquier
          formato o soporte o medio de explotación o comunicación. Dicha cesión
          de derechos no está sujeta a ninguna limitación de carácter temporal
          ni territorial, esto es, se realiza para todo el mundo y por todo el
          tiempo de vigencia legal de los mismos. MERCADOGAMER podrá ejercer los
          derechos de explotación de las fotografías en la forma que estime más
          conveniente, y podrá incluso transmitirlos o cederlos con carácter
          exclusivo o no a terceros en los términos y condiciones que considere
          oportunos.
        </span>
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >El usuario garantiza que es pleno titular de los derechos que se
          ceden a MERCADOGAMER en virtud de esta cláusula, y que el uso y
          explotación de los mismos por MERCADOGAMER no supondrá violación
          alguna de derechos de propiedad intelectual, ni de imagen, ni, en
          general, de ninguna otra clase, que correspondan a cualesquiera
          terceros, obligándose a indemnizar y a mantener indemne a MERCADOGAMER
          en caso de infracción.
        </span>
      </p>
      <p class="terms-content" dir="ltr">
        <span>REGISTRACIÓN - VENDEDOR </span>
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >1. Deberá acceder a la tienda virtual y proceder con la registración.
          Al momento del registro se le solicitara al Usuarios que se registren
          completando todos los campos obligatorios del formulario de
          registración (en adelante el “Formulario”), con datos auténticos y
          actuales.
        </span>
      </p>
      <p class="terms-content" dir="ltr">
        <span>2. Al completar el Formulario, el Usuario acepta: </span>
      </p>
      <ul class="terms-list">
        <li dir="ltr">
          &nbsp;Proveer información verdadera, cierta, correcta, actualizada y
          completa (en adelante los “Datos de Registro”), que serán procesados y
          almacenados en servidores con sistemas de seguridad para su protección
          y seguridad.
        </li>
        <li dir="ltr">
          &nbsp;Mantener actualizados los Datos de Registro, de manera que los
          mismos continúen siendo verdaderos, ciertos, correctos, actualizados,
          y completos.
        </li>
        <li dir="ltr">
          &nbsp;En caso que el Usuario provea información que sea falsa,
          incorrecta, desactualizada o incompleta, o MERCADOGAMER tenga una base
          razonable de sospecha de que dicha información sea falsa, incorrecta,
          desactualizada o incompleta, MERCADOGAMER tendrá el derecho de
          suspender o terminar la posibilidad del Usuario de utilizar los
          Servicios, así como a rechazar cualquier nuevo o futuro uso de los
          Servicios por dicho Usuario (o sólo alguno de ellos).
        </li>
      </ul>
      <p class="terms-content" dir="ltr">
        <span
          >3. Una vez completado el Formulario se le informará al Usuario dicha
          aceptación y el registro de su cuenta (en adelante la “Cuenta”), vía
          correo electrónico, a la misma dirección ingresada en el
          Formulario.</span
        >
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >4. El Usuario deberá tener capacidad legal para contratar y no
          encontrarse bajo ningún impedimento legal o de hecho para
          contratar.</span
        >
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >5. El Usuario, una vez registrado, se obliga a preservar la
          confidencialidad de su contraseña. En consecuencia, el Usuario será
          responsable por el acceso a los Servicios y las operaciones que se
          realicen con su Cuenta y/o Dirección de Correo Electrónico y
          Contraseña, y se obliga a indemnizar y mantener indemne a la Empresa
          y/o los demás Usuarios, Proveedores o terceros, por todo costo, gasto,
          daño o perjuicio (incluyendo los honorarios legales) que la Empresa
          y/o los demás Usuarios, Proveedores o terceros pudieren tener que
          pagar o pudieren sufrir como consecuencia, directa o indirecta, de
          cualquier acceso a los Servicios y/ u operaciones realizadas con su
          Cuenta y/o Dirección de Correo Electrónico y Contraseña.</span
        >
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >6. En virtud de lo expuesto anteriormente, el Usuario se obliga a
          notificar inmediatamente a la Empresa cualquier uso no autorizado o
          robo de su contraseña o cualquier otra violación a la seguridad, y de
          proveer la prueba documentada pertinente que sea razonablemente
          requerida por la Empresa. Asimismo, la Empresa no será responsable por
          cualquier pérdida incurrida por el Usuario como consecuencia de la
          utilización de la Cuenta por parte de un tercero, haya sucedido esto
          con o sin el conocimiento del Usuario. Sin embargo, el Usuario será
          responsable por cualquier pérdida incurrida por la Empresa o cualquier
          tercero, como consecuencia de la utilización de la Cuenta por parte de
          un tercero.</span
        >
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >7. La Empresa podrá, a su exclusivo arbitrio, dar de baja temporal o
          permanentemente, las Cuentas de aquellos Usuarios que violen los
          Términos y Condiciones, y/o la Política de Privacidad, y/o por
          cualquier otro motivo que MERCADOGAMER considere violatorio de la
          moral, las buenas costumbres, las buenas prácticas profesionales y/o
          comerciales y/o sean perjudiciales para la Empresa o terceros. Dicho
          acto podrá además ser ejercido en adición a otro tipo de sanciones o
          decisiones contempladas en estos Términos y Condiciones.</span
        >
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >Nos reservamos el derecho a denegar el acceso al servicio, a cancelar
          cualesquiera cuentas y a eliminar o modificar cualquier contenido en
          caso de que el usuario incumpliera la legislación aplicable, las
          presentes Condiciones de Uso o cualesquiera otros términos y
          condiciones o políticas aplicables.</span
        >
      </p>
      <p class="terms-content" dir="ltr">
        <span>NORMAS DE PUBLICACIÓN DE LOS ANUNCIOS</span>
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >Con carácter general, la publicación de Anuncios en el Portal estará
          sujeta a las siguientes reglas de redacción:</span
        >
      </p>
      <ul class="terms-list">
        <li dir="ltr">
          &nbsp;Haberse dado de alta como usuario y aceptado las condiciones de
          MERCADOGAMER.
        </li>
        <li dir="ltr">
          &nbsp;Completar todos los apartados del formulario de alta del
          Anuncio.
        </li>
        <li dir="ltr">
          &nbsp;No superar el límite máximo del campo de texto del anuncio.
        </li>
        <li dir="ltr">
          &nbsp;Para la inserción de Anuncios deberá completar correctamente los
          campos obligatorios que así se indican.
        </li>
        <li dir="ltr">
          &nbsp;De igual forma, la publicación de Anuncios en el Portal estará
          sujeta a las siguientes normas:
        </li>
        <li dir="ltr">
          &nbsp;Los clientes que lleguen a la plataforma no pueden ser derivados
          a otras privadas o personales para el pago.
        </li>
        <li dir="ltr">
          &nbsp;Los productos deben ser digitales, no se aceptará ningún
          articulo y/o producto físico.
        </li>
        <li dir="ltr">
          &nbsp;Los productos deben cumplir los requisitos mencionados
          anteriormente o no podrán ser comercializados en la plataforma
        </li>
        <li dir="ltr">&nbsp;No se publicarán Anuncios repetidos.</li>
        <li dir="ltr">
          &nbsp;Las fotografías deben contener el producto que se anuncia.
        </li>
        <li dir="ltr">
          &nbsp;No se publicarán Anuncios relativos a cualquier producto,
          servicio o información que pueda ser contrario a la Ley, la moral, el
          Orden Público o las Normas editoriales del Portal.
        </li>
      </ul>

      <p class="terms-content" dir="ltr">
        <span>MODALIDADES DEL SERVICIO</span>
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >Para poder acceder a la tienda online de MERCADOGAMER, el usuario
          Vendedor deberá dar de alta su perfil dentro de MERCADOGAMER y en
          Mercado Pago para poder utilizar la pasarela de pagos disponible
          dentro del portal.</span
        >
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >Por el uso de MERCADOGAMER, se procederá al cobro de una comisión por
          venta efectuada. Esta será del 7% + IVA, la cual se retendrá mediante
          Mercado Pago, porcentaje que puede ser modificado cuando MERCADOGAMER
          lo crea conveniente con previa notificación de partes.
        </span>
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >Todas estas modalidades pueden ser modificadas o alteradas, en parte
          o completamente, a entera y exclusiva facultad de MERCADOGAMER, sin
          tener derecho el usuario a quejas o reclamos. El usuario Vendedor solo
          tendrá la facultad de, una vez notificado de los cambios que se
          implementaran, de solicitar la baja del servicio sin justificación o
          penalidad alguna.
        </span>
      </p>

      <p class="terms-content" dir="ltr">
        <span>UTILIZACIÓN DE MERCADO PAGO</span>
      </p>

      <p class="terms-content" dir="ltr">
        <span
          >Al publicar un anuncio en el Portal, el usuario vendedor consiente
          expresamente la utilización de Mercado Pago como forma disponible para
          el pago del bien o servicio ofrecido, procediendo a la creación de un
          usuario para poder operar dentro de MERCADOGAMER.
        </span>
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >El usuario queda notificado que todos los pagos procesados serán
          liberados a los 14 (catorce) días de confirmada la transacción.
        </span>
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >Asimismo, el usuario declara que ha leído, comprendido y aceptado
          los Términos y Condiciones de utilización de dicho servicio en
          <a
            href="https://www.mercadopago.com.ar/ayuda/terminos-y-politicas_194"
            >https://www.mercadopago.com.ar/ayuda/terminos-y-politicas_194</a
          >.
        </span>
      </p>

      <p class="terms-content" dir="ltr">
        <span>OBLIGACIONES Y PENALIDADES DEL VENDEDOR</span>
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >El usuario vendedor debe tener capacidad legal para vender el bien o
          servicio objeto de su oferta. Asimismo, debe cumplir con todas las
          obligaciones regulatorias pertinentes y contar con los registros,
          habilitaciones, permisos y/o autorizaciones exigidos por la normativa
          aplicable para la venta de los bienes, artículos y/o servicios
          ofrecidos. Si el usuario vendedor ha recibido una oferta queda
          obligado a intentar comunicarse con el comprador y completar la
          operación. La cancelación de una venta por parte del Usuario vendedor
          impactará negativamente en su reputación, pudiendo ser advertido,
          suspendido y/o inhabilitado por MERCADOGAMER para continuar operando
          en el sitio.
        </span>
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >Asimismo, en caso de que el vendedor no entregue el producto en
          tiempo y forma, o incumpla con los tiempos de devolución o reembolso,
          MERCADOGAMER se reserva la facultad de suspender y/o inhabilitar al
          usuario y su tienda.
        </span>
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >Dado que MERCADOGAMER es un punto de encuentro entre comprador y
          vendedor y no participa de las operaciones que se realizan entre
          ellos, el vendedor será responsable por todas las obligaciones y
          cargas impositivas que correspondan por la venta de sus bienes y/o
          servicios, sin que pudiera imputársele a MERCADOGAMER algún tipo de
          responsabilidad por incumplimientos en tal sentido.
        </span>
      </p>

      <p class="terms-content" dir="ltr">
        <span>CANCELACIONES Y DEVOLUCIONES DE PRODUCTO</span>
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >El usuario comprador tendrá derecho a solicitar la cancelación del
          producto, y el usuario vendedor no podrá oponerse ni ejecutar cargos
          extras, en los siguientes casos
        </span>
      </p>
      <ul class="terms-list">
        <li dir="ltr">&nbsp;Si el producto no fue entregado</li>

        <li dir="ltr">
          &nbsp;O si el producto entregado con condice con la descripción del
          articulo comprado.
        </li>
      </ul>
      <p class="terms-content" dir="ltr">
        <span
          >De aplicar estas circunstancias el comprador deberá iniciar la acción
          de cancelación de compra y/o devolución de pago en un plazo no mayor a
          tres días hábiles, mediante gestión en plataforma MERCADOGAMER, quien
          procederá a la devolución de la suma abonada.
        </span>
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >El usuario comprador deberá tener presente que los plazos de
          devolución pueden verse afectados por posibles demoras en el plazo de
          acreditación (que estará sujeta a los tiempos de Mercado Pago y la
          entidad bancaria).
        </span>
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >En caso de que el usuario cliente no esté satisfecho con el producto
          recibido, por tener alguna falla, puede reclamar la Garantía
          MERCADOGAMER, dentro de los 3 días de recibido el producto (periodo
          donde se produce el cierre de la transacción y calificación del
          vendedor), pudiendo solicitar:
        </span>
      </p>
      <ul class="terms-list">
        <li dir="ltr">
          &nbsp;La cancelación de la compra y el reembolso correspondiente.
        </li>

        <li dir="ltr">&nbsp;El cambio del producto por uno igual.</li>
      </ul>

      <p class="terms-content" dir="ltr">
        <span>PROHIBICIONES</span>
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >Los usuarios no podrán: (a) manipular los precios de los artículos;
          (b) mantener cualquier tipo de comunicación por otro medio distinto al
          servicio de mensajería que ofrece MERCADOGAMER (incluyendo mails,
          redes sociales, WhatsApp, etc.) ya sea durante la oferta del bien o
          con posterioridad a ella; (c) publicar o vender artículos prohibidos
          por los Términos y Condiciones Generales, demás políticas de
          MERCADOGAMER o leyes vigentes; (d) insultar o agredir a otros
          Usuarios; (e) derivar a los usuarios clientes que lleguen a la tienda
          virtual a otras plataformas privadas o personales para ejecutar el
          pago correspondiente.
        </span>
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >Este tipo de actividades será investigado por MERCADOGAMER y el
          infractor podrá ser sancionado con la suspensión o cancelación de la
          oferta e incluso de su inscripción como usuario de MERCADOGAMER y/o de
          cualquier otra forma que estime pertinente, sin perjuicio de las
          acciones legales a que pueda dar lugar por la configuración de delitos
          o contravenciones o los perjuicios civiles que pueda causar a los
          usuarios oferentes.
        </span>
      </p>

      <p class="terms-content" dir="ltr">
        <span>MODIFICACIÓN DE LAS CONDICIONES DE VENTA</span>
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >Nos reservamos el derecho a realizar cambios a nuestro sitio web, a
          nuestras políticas y a nuestros términos y condiciones, incluyendo a
          las presentes Condiciones de Venta, en cualquier momento. Quedarás
          sujeto a los términos y condiciones, políticas y Condiciones de Venta
          en vigor en el momento en que realices tu pedido, a menos que, por ley
          o por requerimiento de las autoridades públicas, se deba efectuar un
          cambio a dichos términos y condiciones, políticas o a las presentes
          Condiciones de Venta (en cuyo caso, tales cambios podrían resultar
          aplicables a cualesquiera pedidos que hubieras realizado
          anteriormente). Si alguna de las presentes condiciones fuera declarada
          inválida, nula o por cualquier causa ineficaz, dicha condición se
          entenderá excluida sin que dicha declaración pueda afectar a la
          validez ni a la exigibilidad del resto de condiciones.
        </span>
      </p>

      <p class="terms-content" dir="ltr">
        <span>MODIFICACIÓN DE LAS CONDICIONES DE VENTA</span>
      </p>

      <p class="terms-content" dir="ltr">
        <span>REGISTRACIÓN - COMPRADOR</span>
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >1. Los Servicios de la compra en la tienda virtual pueden hacerse
          únicamente con registro. Al momento del registro se le solicitara al
          Usuarios que se registren completando todos los campos obligatorios
          del formulario de registración (en adelante el “Formulario”), con
          datos auténticos y actuales.
        </span>
      </p>
      <p class="terms-content" dir="ltr">
        <span>2. Al completar el Formulario, el Usuario acepta: </span>
      </p>
      <ul class="terms-list">
        <li dir="ltr">
          &nbsp;Proveer información verdadera, cierta, correcta, actualizada y
          completa (en adelante los “Datos de Registro”), que serán procesados y
          almacenados en servidores con sistemas de seguridad para su protección
          y seguridad.
        </li>

        <li dir="ltr">
          &nbsp;Mantener actualizados los Datos de Registro, de manera que los
          mismos continúen siendo verdaderos, ciertos, correctos, actualizados,
          y completos.
        </li>
        <li dir="ltr">
          &nbsp;•En caso que el Usuario provea información que sea falsa,
          incorrecta, desactualizada o incompleta, o MERCADOGAMER tenga una base
          razonable de sospecha de que dicha información sea falsa, incorrecta,
          desactualizada o incompleta, MERCADOGAMER tendrá el derecho de
          suspender o terminar la posibilidad del Usuario de utilizar los
          Servicios, así como a rechazar cualquier nuevo o futuro uso de los
          Servicios por dicho Usuario (o sólo alguno de ellos).
        </li>
      </ul>
      <p class="terms-content" dir="ltr">
        <span
          >3. Una vez completado el Formulario se le informará al Usuario dicha
          aceptación y el registro de su cuenta (en adelante la “Cuenta”), vía
          correo electrónico, a la misma dirección ingresada en el
          Formulario.</span
        >
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >4. El Usuario deberá tener capacidad legal para contratar y no
          encontrarse bajo ningún impedimento legal o de hecho para
          contratar.</span
        >
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >5. El Usuario, una vez registrado, se obliga a preservar la
          confidencialidad de su contraseña. En consecuencia, el Usuario será
          responsable por el acceso a los Servicios y las operaciones que se
          realicen con su Cuenta y/o Dirección de Correo Electrónico y
          Contraseña, y se obliga a indemnizar y mantener indemne a la Empresa
          y/o los demás Usuarios, Proveedores o terceros, por todo costo, gasto,
          daño o perjuicio (incluyendo los honorarios legales) que la Empresa
          y/o los demás Usuarios, Proveedores o terceros pudieren tener que
          pagar o pudieren sufrir como consecuencia, directa o indirecta, de
          cualquier acceso a los Servicios y/ u operaciones realizadas con su
          Cuenta y/o Dirección de Correo Electrónico y Contraseña.</span
        >
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >6. En virtud de lo expuesto anteriormente, el Usuario se obliga a
          notificar inmediatamente a la Empresa cualquier uso no autorizado o
          robo de su contraseña o cualquier otra violación a la seguridad, y de
          proveer la prueba documentada pertinente que sea razonablemente
          requerida por la Empresa. Asimismo, la Empresa no será responsable por
          cualquier pérdida incurrida por el Usuario como consecuencia de la
          utilización de la Cuenta por parte de un tercero, haya sucedido esto
          con o sin el conocimiento del Usuario. Sin embargo, el Usuario será
          responsable por cualquier pérdida incurrida por la Empresa o cualquier
          tercero, como consecuencia de la utilización de la Cuenta por parte de
          un tercero.</span
        >
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >7. La Empresa podrá, a su exclusivo arbitrio, dar de baja temporal o
          permanentemente, las Cuentas de aquellos Usuarios que violen los
          Términos y Condiciones, y/o la Política de Privacidad, y/o por
          cualquier otro motivo que MERCADOGAMER considere violatorio de la
          moral, las buenas costumbres, las buenas prácticas profesionales y/o
          comerciales y/o sean perjudiciales para la Empresa o terceros. Dicho
          acto podrá además ser ejercido en adición a otro tipo de sanciones o
          decisiones contempladas en estos Términos y Condiciones.</span
        >
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >Nos reservamos el derecho a denegar el acceso al servicio, a cancelar
          cualesquiera cuentas y a eliminar o modificar cualquier contenido en
          caso de que el usuario incumpliera la legislación aplicable, las
          presentes Condiciones de Uso o cualesquiera otros términos y
          condiciones o políticas aplicables.</span
        >
      </p>

      <p class="terms-content" dir="ltr">
        <span>NUESTRO CONTRATO</span>
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >Cuando realices tu pedido, te enviaremos un mensaje confirmando la
          compra y recepción de tu pedido.
        </span>
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >Aceptas recibir facturas de tus compras electrónicamente. Asimismo,
          puedes obtener una copia de tu factura en papel contactándonos
          gratuitamente por e-mail, chat o teléfono de forma directa con tu
          vendedor.
        </span>
      </p>

      <p class="terms-content" dir="ltr">
        <span>GARANTÍA DE FABRICACION</span>
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >Las políticas de garantías serán independientes y gestionadas por el
          usuario vendedor. Es por ello que los usuarios deberán comunicar, su
          situación o reclamo, en forma directa usuario
          vendedor/anunciante.</span
        >
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >MERCADOGAMER no tiene responsabilidad alguna sobre, pero no limitante
          a, la calidad del producto o fallas de fabricación, ya que el rol de
          MERCADOGAMER es solo a modo de facilitador de la tienda virtual.</span
        >
      </p>

      <p class="terms-content" dir="ltr">
        <span>CANCELACIONES Y DEVOLUCIONES DE PRODUCTO</span>
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >El usuario comprador tendrá derecho a solicitar la cancelación del
          producto, y el usuario vendedor no podrá oponerse ni ejecutar cargos
          extras, en los siguientes casos:</span
        >
      </p>
      <ul class="terms-list">
        <li dir="ltr">&nbsp;Si el producto no fue entregado</li>

        <li dir="ltr">
          &nbsp;O si el producto entregado con condice con la descripción del
          articulo comprado.
        </li>
      </ul>
      <p class="terms-content" dir="ltr">
        <span
          >De aplicar estas circunstancias el comprador deberá iniciar la acción
          de cancelación de compra y/o devolución de pago en un plazo no mayor a
          tres días hábiles, mediante gestión en plataforma MERCADOGAMER, quien
          procederá a la devolución de la suma abonada.
        </span>
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >El usuario comprador deberá tener presente que los plazos de
          devolución pueden verse afectados por posibles demoras en el plazo de
          acreditación (que estará sujeta a los tiempos de Mercado Pago y la
          entidad bancaria).</span
        >
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >En caso de que el usuario cliente no esté satisfecho con el producto
          recibido, por tener alguna falla, puede reclamar la Garantía
          MERCADOGAMER, dentro de los 3 días de recibido el producto (periodo
          donde se produce el cierre de la transacción y calificación del
          vendedor), pudiendo solicitar:</span
        >
      </p>
      <ul class="terms-list">
        <li dir="ltr">
          &nbsp;La cancelación de la compra y el reembolso correspondiente.
        </li>

        <li dir="ltr">&nbsp;El cambio del producto por uno igual.</li>
      </ul>

      <p class="terms-content" dir="ltr">
        <span>OBLIGACIONES DEL COMPRADOR</span>
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >El Comprador está obligado a intentar comunicarse con el vendedor y
          completar la operación si ha realizado una oferta por un artículo
          salvo que la operación esté prohibida por la ley o los Términos y
          Condiciones Generales y demás políticas de MERCADOGAMER, en cuyo caso
          no estará obligado a concretar la operación.</span
        >
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >Al ofertar por un bien o contratar un servicio, el Usuario acepta
          quedar obligado por las condiciones de venta incluidas en la
          publicación, en la medida en que las mismas no infrinjan las leyes o
          los Términos y Condiciones Generales y demás políticas de
          MERCADOGAMER. La oferta de compra de un bien o contratación de un
          servicio es irrevocable salvo en circunstancias excepcionales, tales
          como que el vendedor cambie sustancialmente la descripción del bien o
          servicio después de realizada alguna oferta, que exista un claro error
          tipográfico, o que no pueda verificar la identidad del vendedor.</span
        >
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >Las ofertas de compra sólo serán consideradas válidas una vez que
          hayan sido procesadas por el sistema informático de
          MERCADOGAMER.</span
        >
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >Ante cualquier inconveniente, el usuario comprador podrá iniciar un
          reclamo en el Sitio contra su vendedor, dentro de los 3 días de
          realizada la compra.</span
        >
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >Impuestos: tal como lo establece la normativa fiscal vigente, el
          comprador debe exigir factura o ticket al vendedor como comprobante de
          la operación. El vendedor no estará obligado a emitir factura o ticket
          sólo en el caso de tratarse de una persona física que efectúa ventas
          ocasionalmente.</span
        >
      </p>

      <p class="terms-content" dir="ltr">
        <span>CALIFICACION DE LOS VENDEDORES</span>
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >Los usuarios compradores podrán calificar el servicio y producto
          brindado por los vendedores, esto facilitara tener un score de
          puntuación y poder mantener la excelencia del servicio.</span
        >
      </p>
      <p class="terms-content" dir="ltr">
        <span>INDEPENDENCIA DE LAS CLAUSULAS</span>
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >Si alguna de las presentes condiciones fuera declarada inválida, nula
          o por cualquier causa ineficaz, dicha condición se entenderá excluida
          sin que dicha declaración pueda afectar a la validez ni a la
          exigibilidad del resto de condiciones.</span
        >
      </p>
      <p class="terms-content" dir="ltr">
        <span>LEYES VIGENTES Y JURISDICCIÓN</span>
      </p>
      <p class="terms-content" dir="ltr">
        <span
          >Los Términos y Condiciones están regidos por las leyes de la
          República Argentina y las partes se sujetan a la jurisdicción
          exclusiva de los Tribunales Ordinarios en lo Comercial de la República
          Argentina renunciando así a cualquier otro fuero o jurisdicción que
          pudiera corresponderle. Cualquier acción relacionada con los Términos
          debe ser presentada ante los tribunales ordinarios de la Ciudad
          Autónoma de Buenos Aires y Usted acepta de manera irrevocable la
          jurisdicción pactada.</span
        >
      </p>
    </div>
  </div>
</div>
