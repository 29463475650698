<div class="page-title">Cambiar contraseña</div>
<div class="content">
  <app-mercado-input
    label="Nueva contraseña"
    type="password"
    [value]="password"
    (valueChange)="password = $event"
  ></app-mercado-input>
  <app-mercado-input
    label="Repetir nueva contraseña"
    type="password"
    [value]="confirmPassword"
    (valueChange)="confirmPassword = $event"
    [error]="!!error"
    [helper]="error"
  ></app-mercado-input>
  <div class="action">
    <app-button width="200px" (btnClick)="changePassword()">Guardar</app-button>
  </div>
</div>
